<template>
  <div>
    <div class="list-content">
      <el-card shadow="never">
        <div class="card-table">
          <div class="table-title">所属内容</div>
          <div class="table-content">
            <div>
              {{detail.contentText ? detail.contentText.content: '-'}}
            </div>
            <div></div>
          </div>
        </div>
      </el-card>
    </div>
    <div style="margin-top: 20px" class="list-content">
      <el-row :gutter="20">
        <el-col :span="8">
          <div>评论者 : {{detail.nickname ? detail.nickname : '-'}}</div>
        </el-col>
        <el-col :span="8">
          <div>架构/会内职务 : {{ detail.title_name ? detail.title_name : '-'}}</div>
        </el-col>
        <el-col :span="8">
          <div>审核状态 : <span class="dot"
              :class="detail.is_check === 0 ? 'yellow-dot': (detail.is_check === 1 ? 'gray-dot' : 'green-dot')"></span>{{detail.check_text}}
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <div>内容ID : {{detail.target_id}}</div>
        </el-col>
        <el-col :span="8">
          <div>评论ID : {{ detail.id}}</div>
        </el-col>
        <el-col :span="8">
          <div>评论者IP : {{detail.user_ip}}</div>
        </el-col>
      </el-row>
      <div style="font-size: 20px;">评论数据</div>
      <el-table style="margin-top: 20px" :data="tableData" border stripe class="thead-light">
        <el-table-column label="举报数" prop="complaints_count"></el-table-column>
        <el-table-column label="回复数" prop="reply"></el-table-column>
        <el-table-column label="用户点赞数" prop="like"></el-table-column>
        <el-table-column label="运营点赞数">
          <template slot-scope="scope">
            <el-input v-model="scope.row.virtual_like" type="number" min="0"></el-input>

          </template>
        </el-table-column>
        <el-table-column label="评论时间" prop="time"></el-table-column>
      </el-table>
      <div style="font-size: 20px;;height: 80px;line-height: 80px">评论内容</div>
      <div class="card-table">
        <div class="table-title">内容详情</div>
        <div class="table-content">
          <div>{{detail.content}}</div>
          <div></div>
        </div>
      </div>
    </div>
    <fixed-action-bar>
      <el-button type="primary" size="medium" @click="save">保存
      </el-button>
      <el-button size="medium" @click="cancelConfig">返回</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
import { getDetail, getUpdateVirtual } from "../../api/experience-comments";
import FixedActionBar from "../../../../base/layout/FixedActionBar";

export default {
  components: {
    FixedActionBar,
  },
  data() {
    return {
      detail: [],
      tableData: [],
      id: 0,
    };
  },
  created() {
    this.id = this.$route.params.id;
    getDetail({ id: this.id }).then((res) => {
      this.detail = res.data;
      this.tableData.push({
        complaints_count: this.detail.complaints_count,
        reply: this.detail.replies_count,
        like: this.detail.likes_count,
        virtual_like: this.detail.praise_count,
        time: this.detail.create_time,
      });
    });
  },
  methods: {
    cancelConfig() {
      window.history.go(-1);
    },
    save() {
      getUpdateVirtual({
        id: this.id,
        praise_count: Number(this.tableData[0].virtual_like),
      }).then((res) => {
        this.$message.success("保存成功");
      });

      window.history.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-content {
  .card-table {
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }

  .table-title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 14px;

    padding: 15px 24px;
    background: rgba(250, 250, 250, 1);
    border-bottom: 1px solid #e9e9e9;
  }
  .table-content {
    padding: 16px 24px;
  }
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 36px;
  }
  .dot {
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    vertical-align: middle;
  }

  .yellow-dot {
    background: rgba(255, 203, 44, 1);
  }

  .green-dot {
    background: rgba(19, 206, 102, 1);
  }

  .gray-dot {
    background: rgba(144, 147, 153, 1);
  }
  .user_ip {
    height: 50px;
  }
}
</style>
